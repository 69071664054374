const Footer = ({ showAskAnythingDialog }) => {
    const handleLinkClick = (event) => {
        event.preventDefault();
        showAskAnythingDialog();
    };
    return (<footer className="bg-white py-4 mt-auto">
        <div className="container px-5">
            <div className="row align-items-center justify-content-between flex-column flex-sm-row">
                <div className="col-auto">
                    <div className="small m-0">Copyright &copy; curs@viorelspinu.com 2024; v 1.1.3</div>
                </div>
                <div className="col-auto">
                    <a className="small" href="/#/confidentiality">Politica de Confidențialitate</a>
                    <span className="mx-1">&middot;</span>
                    <a className="small" href="/#/terms">Termeni si Condiții</a>
                    <span className="mx-1">&middot;</span>
                    <a className="small" href="#" onClick={showAskAnythingDialog}>Contact</a>
                </div>
            </div>
        </div>
    </footer>);
}

export default Footer;